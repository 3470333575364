<template>
  <ValidationObserver ref="step2Form" v-slot="{ valid }">
    <div class="box plain-shadow-on-desktop">
      <ProfileCommon></ProfileCommon>
      <div class="form-box">
        <div class="step2">
          <div class="step-title">Your addy handle</div>
          <ValidationProvider name="email" rules="required|addy-handle" v-slot="{ errors, passed, failed }">
            <div class="field addy-handle-field">
              <div :class="`control has-icons-right ${addyHandleFieldIsLoading ? 'is-loading' : ''}`">
                <span class="special-character">$</span>
                <input
                  :class="`input is-medium-height ${typeOfAddyHandleField(passed, failed)}`"
                  type="text"
                  placeholder="handle"
                  v-model.trim="addyHandle"
                  maxlength="16"
                  @input="onInput">
                <span class="icon is-small is-right">
                  <img
                    v-show="(failed && !addyHandleFieldIsLoading) || (addyHandleVerified && !addyHandleIsAvailable)"
                    class="icon-exclamation"
                    src="@assets/icons/exclamation.svg"
                    alt="is-danger">
                  <img
                    v-show="(!addyHandleVerified && passed && !addyHandleFieldIsLoading) || (addyHandleVerified && passed && addyHandleIsAvailable)"
                    class="icon-check"
                    src="@assets/icons/check.svg"
                    alt="is-success">
                </span>
              </div>
              <p class="help is-danger" v-show="failed || (addyHandleVerified && !addyHandleIsAvailable)">{{errors[0] || 'That addy handle is taken.'}}</p>
            </div>
          </ValidationProvider>
          <div class="step-hint has-text-centered">Your unique addy handle will allow you to access some cool features in the future!</div>
        </div>
      </div>
    </div>
    <BottomButtons :nextDisabled="!valid || !addyHandleIsAvailable" :loading="nextIsLoading" @goNext="goNext" @goBack="goBack"></BottomButtons>
  </ValidationObserver>
</template>
<script>
import { isAddyHandleAvailable, updateInvestorProfile } from '@api/signup'
import formMixins from '@utils/mixins/form-mixins.js'
import setupProfile from '@utils/mixins/setup-profile.js'
import ProfileCommon from './ProfileCommon.vue'
import BottomButtons from '@components/button/bottom-buttons.vue'
import { mapState } from 'vuex'

export default {
  mixins: [formMixins, setupProfile],
  components: {
    ProfileCommon,
    BottomButtons,
  },
  data() {
    return {
      addyHandle: '',
      addyHandleFieldIsLoading: false,
      addyHandleVerified: false,
      addyHandleIsAvailable: false,
      nextIsLoading: false,
      timer: undefined,
      inputTimer: undefined,
    }
  },
  computed: {
    ...mapState('mixpanel', ['mixpanel']),
  },
  methods: {
    typeOfAddyHandleField(passed, failed) {
      if (this.addyHandleFieldIsLoading) return ''
      if (this.addyHandleVerified && !this.addyHandleIsAvailable) {
        return 'is-danger'
      } else {
        return this.classForField({ passed, failed })
      }
    },
    checkUniquenessOfAddyHandle() {
      if (!this.addyHandle || this.addyHandleFieldIsLoading) return
      this.addyHandleFieldIsLoading = true
      isAddyHandleAvailable(`$${this.addyHandle}`).then((data) => {
        if (!data.success) return
        this.addyHandleFieldIsLoading = false
        this.addyHandleVerified = true
        this.addyHandleIsAvailable = data.data.available
      })
    },
    onInput() {
      this.resetAddyHandleFlags()
      this.inputTimer && clearTimeout(this.inputTimer)
      this.timer && clearTimeout(this.timer)
      // the inputTimer is to fix the issue that vee-validate uses the previous input state against the validation
      this.inputTimer = setTimeout(() => {
        this.validateThenFetch()
      }, 200)
    },
    validateThenFetch() {
      this.$refs.step2Form.validate().then((success) => {
        if (!success) return
        this.postponedFetch()
      })
    },
    postponedFetch() {
      this.timer = setTimeout(() => {
        this.checkUniquenessOfAddyHandle()
      }, 1000)
    },
    resetAddyHandleFlags() {
      if (!this.addyHandleVerified) return
      this.addyHandleVerified = false
      this.addyHandleIsAvailable = false
    },
    goNext() {
      this.nextIsLoading = true
      this.$refs.step2Form.validate().then((success) => {
        if (!success || !this.addyHandleIsAvailable) {
          this.nextIsLoading = false
          return
        }
        const email = JSON.parse(localStorage.getItem('investor')).email
        updateInvestorProfile({
          email,
          addy_handle: '$' + this.addyHandle,
        }).then(() => {
          this.nextIsLoading = false
          this.$emit('updateProgressRing', 'next')
          this.mixpanel.track('a8naawnh')
          this.$router.push(`/signup/profile/${Number(this.activeStep) + 1}`)
        })
      })
    },
    goBack() {
      this.$emit('updateProgressRing', 'back')
      this.$router.replace('/signup/profile/1?noRedirect=true')
    }
  }
}
</script>
<style lang="scss" scoped>
.box {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 26px;
  padding-bottom: 26px;
  box-shadow: 0px 4px 50px rgba(215, 145, 51, 0.15);
  .step2 {
    .field.addy-handle-field {
      margin-bottom: 0;
    }
    .step-title {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 12px;
      padding-left: 10px;
    }
    .step-hint {
      font-size: 16px;
      line-height: 19px;
      padding: 90px 20px 10px;
    }
    .addy-handle-field {
      .special-character {
        position: absolute;
        left: 16px;
        top: 0;
        line-height: 50px;
        z-index: 1;
        color: #4A26AA;
      }
      input {
        padding-left: 26px;
      }
    }
  }
}
</style>
