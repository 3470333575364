var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"step1Form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('div',{staticClass:"box plain-shadow-on-desktop"},[_c('ProfileCommon'),_c('div',{staticClass:"form-box"},[_c('div',{staticClass:"step1"},[_c('div',{staticClass:"step-title"},[_vm._v("Legal name")]),_c('ValidationProvider',{attrs:{"name":"firstName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var passed = ref.passed;
var failed = ref.failed;
return [_c('div',{staticClass:"field first-name-field"},[_c('div',{staticClass:"control has-icons-right"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.firstName),expression:"firstName",modifiers:{"trim":true}}],class:("input is-medium-height " + (_vm.classForField({ passed: passed, failed: failed }))),attrs:{"type":"text","placeholder":"First name"},domProps:{"value":(_vm.firstName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.firstName=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"icon is-small is-right"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"icon-exclamation",attrs:{"src":require("../../assets/icons/exclamation.svg"),"alt":"is-danger"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"icon-check",attrs:{"src":require("../../assets/icons/check.svg"),"alt":"is-success"}})])]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var passed = ref.passed;
var failed = ref.failed;
return [_c('div',{staticClass:"field last-name-field"},[_c('div',{staticClass:"control has-icons-right"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.lastName),expression:"lastName",modifiers:{"trim":true}}],class:("input is-medium-height " + (_vm.classForField({ passed: passed, failed: failed }))),attrs:{"type":"text","placeholder":"Last name"},domProps:{"value":(_vm.lastName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.lastName=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"icon is-small is-right"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"icon-exclamation",attrs:{"src":require("../../assets/icons/exclamation.svg"),"alt":"is-danger"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"icon-check",attrs:{"src":require("../../assets/icons/check.svg"),"alt":"is-success"}})])]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)])],1),_c('BottomButtons',{attrs:{"backButtonVisible":false,"nextDisabled":!valid,"loading":_vm.nextIsLoading},on:{"goNext":_vm.goNext}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }