<template>
  <ValidationObserver ref="step4Form" v-slot="{ valid }">
    <div class="box plain-shadow-on-desktop">
      <ProfileCommon></ProfileCommon>
      <div class="form-box">
        <div class="step4">
          <div class="step-title">Date of birth</div>
          <div class="step-hint">To invest, you must be <span data-testid="age">{{age}}</span> years or older.</div>
          <div class="is-flex is-justify-content-space-between birthday">
            <ValidationProvider name="year" rules="required" v-slot="{ errors, passed, failed }">
              <b-field :type="classForField({ passed, failed })" :message="errors[0]">
                <b-select
                  name="year"
                  placeholder="Year"
                  v-model="year"
                  class="is-medium-height is-blue year"
                  @input="getDays"
                >
                  <option
                    v-for="option in years"
                    :value="option"
                    :key="option"
                  >{{ option }}</option>
                </b-select>
              </b-field>
            </ValidationProvider>
            <ValidationProvider name="month" rules="required" v-slot="{ errors, passed, failed }">
              <b-field :type="classForField({ passed, failed })" :message="errors[0]">
                <b-select
                  name="month"
                  placeholder="Month"
                  v-model="month"
                  class="is-medium-height is-blue month"
                  :disabled="!year"
                  @input="getDays"
                >
                  <option
                    v-for="option in months"
                    :value="option.code"
                    :key="option.code"
                  >{{ option.name }}</option>
                </b-select>
              </b-field>
            </ValidationProvider>
            <ValidationProvider name="day" rules="required" v-slot="{ errors, passed, failed }">
              <b-field :type="classForField({ passed, failed })" :message="errors[0]">
                <b-select
                  name="day"
                  placeholder="Day"
                  v-model="day"
                  class="is-medium-height is-blue day"
                  :disabled="!year || !month"
                >
                  <option
                    v-for="option in days"
                    :value="option"
                    :key="option"
                  >{{ option }}</option>
                </b-select>
              </b-field>
            </ValidationProvider>
          </div>
        </div>
      </div>
    </div>
    <BottomButtons :nextDisabled="!valid" :loading="nextIsLoading" @goNext="goNext" @goBack="goBack"></BottomButtons>
    <ConfirmModal title="Oops!" :text="`To invest, you must be ${age} or older.`" :showModal="showModal" @closeModal="showModal = false"></ConfirmModal>
  </ValidationObserver>
</template>
<script>
import { DateTime, Interval } from 'luxon'
import formMixins from '@utils/mixins/form-mixins.js'
import setupProfile from '@utils/mixins/setup-profile.js'
import Months from '@utils/data/months.js'
import ProfileCommon from './ProfileCommon.vue'
import BottomButtons from '@components/button/bottom-buttons.vue'
import ConfirmModal from '@components/modal/confirm-modal.vue'
import { updateInvestorProfile } from '@api/signup'
import { mapState } from 'vuex'

export default {
  mixins: [formMixins, setupProfile],
  components: {
    ProfileCommon,
    BottomButtons,
    ConfirmModal,
  },
  data() {
    return {
      age: '18',
      year: undefined,
      years: [],
      month: undefined,
      months: Months,
      day: undefined,
      days: [],
      showModal: false,
      nextIsLoading: false,
    }
  },
  created() {
    this.getAgeOfMajority()
    this.getYears()
    this.getDays()
  },
  computed: {
    ...mapState('mixpanel', ['mixpanel']),
    birthdate() {
      return `${this.year}-${Number(this.month) < 10 ? '0' + this.month : this.month}-${Number(this.day) < 10 ? '0' + this.day : this.day}`
    },
  },
  methods: {
    getAgeOfMajority() {
      const caRegions = ['BC', 'NB', 'NL', 'NT', 'NS', 'NU', 'YT']
      const usRegions = ['AL', 'NE']
      const age19Regions = [...caRegions, ...usRegions]

      const investor = JSON.parse(localStorage.getItem('investor'))
      const province = investor.administrativeAreaLevel1
      if (age19Regions.includes(province)) this.age = '19'
      if (province === 'MS') this.age = '21'
    },
    getYears() {
      const maxYear = new Date().getFullYear() - this.age
      const minYear = new Date().getFullYear() - 100
      const arr = []
      for (let index = maxYear; index > minYear; index--) {
        arr.push(index)
      }
      this.years = arr
    },
    getDays() {
      if (!this.year || !this.month) return []
      const _28days = []
      for (let index = 1; index <= 28; index++) {
        _28days.push(index)
      }
      const _29days = [..._28days, 29]
      const _30days = [..._29days, 30]
      const _31days = [..._30days, 31]
      const map = {
        _28days,
        _29days,
        _30days,
        _31days,
      }
      const daysInSelectedMonth = DateTime.local(this.year, this.month).daysInMonth
      this.days = map[`_${daysInSelectedMonth}days`]
    },
    validateAge() {
      const { years } = Interval.fromDateTimes(new Date(this.birthdate), new Date()).toDuration('years').toObject()
      return years >= Number(this.age)
    },
    goNext() {
      this.nextIsLoading = true
      if (!this.validateAge()) {
        this.nextIsLoading = false
        this.showModal = true
        return
      }
      this.$refs.step4Form.validate().then((success) => {
        if (!success) {
          this.nextIsLoading = false
          return
        }
        const email = JSON.parse(localStorage.getItem('investor')).email
        updateInvestorProfile({
          email,
          birthdate: this.birthdate,
        }).then(() => {
          this.nextIsLoading = false
          this.$emit('updateProgressRing', 'next')
          this.mixpanel.track('794d9js4')
          this.$router.push(`/signup/profile/${Number(this.activeStep) + 1}`)
        })
      })
    },
    goBack() {
      this.$emit('updateProgressRing', 'back')
      this.$router.replace('/signup/profile/3?noRedirect=true')
    },
  }
}
</script>
<style lang="scss" scoped>
.box {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 26px;
  padding-bottom: 26px;
  box-shadow: 0px 4px 50px rgba(215, 145, 51, 0.15);
  .step4 {
    .step-title {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 6px;
      padding-left: 10px;
    }
    .step-hint {
      margin-bottom: 12px;
      padding-left: 10px;
    }
    .birthday {
      padding-bottom: 120px;
      > span {
        flex: 1;
        &:nth-of-type(2),
        &:nth-of-type(3) {
          padding-left: 8px;
        }
      }
    }
  }
}
</style>
<style scoped>
.control  >>> .select,
.control  >>> .select select {
  width: 100%;
}
</style>
