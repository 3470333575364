<template>
  <ValidationObserver ref="step5Form" v-slot="{ valid }">
    <div class="box plain-shadow-on-desktop">
      <ProfileCommon></ProfileCommon>
      <div class="form-box">
        <div :class="`step5 ${heardAboutUs === 'Other' ? 'is-other' : 'no-other'}`">
          <div class="step-title">How did you hear about us?</div>
          <ValidationProvider name="heardAboutUs" rules="required" v-slot="{ errors, passed, failed }">
            <b-field :type="classForField({ passed, failed })" :message="errors[0]">
              <b-select
                name="heardAboutUs"
                placeholder="Select..."
                v-model="heardAboutUs"
                class="is-medium-height is-blue heard-about-us"
                expanded
              >
                <option
                  v-for="option in wordOfMouth"
                  :value="option"
                  :key="option"
                >{{ option }}</option>
              </b-select>
            </b-field>
          </ValidationProvider>
          <ValidationProvider name="heardAboutUsOther" rules="required" v-slot="{ errors, passed, failed }" v-if="heardAboutUs === 'Other'">
            <div class="field heard-about-us-other-field">
              <div class="control has-icons-right">
                <input :class="`input is-medium-height ${classForField({ passed, failed })}`" placeholder="How did you hear about us?" v-model.trim="heardAboutUsOther">
                <span class="icon is-small is-right">
                  <img
                    v-show="failed"
                    class="icon-exclamation"
                    src="../../assets/icons/exclamation.svg"
                    alt="is-danger">
                  <img
                    v-show="passed"
                    class="icon-check"
                    src="../../assets/icons/check.svg"
                    alt="is-success">
                </span>
              </div>
              <p class="help is-danger" v-show="failed">{{errors[0]}}</p>
            </div>
          </ValidationProvider>
        </div>
      </div>
    </div>
    <BottomButtons :nextDisabled="!valid" :loading="nextIsLoading" @goNext="goNext" @goBack="goBack"></BottomButtons>
  </ValidationObserver>
</template>
<script>
import formMixins from '@utils/mixins/form-mixins.js'
import setupProfile from '@utils/mixins/setup-profile.js'
import ProfileCommon from './ProfileCommon.vue'
import BottomButtons from '@components/button/bottom-buttons.vue'
import { updateInvestorProfile } from '@api/signup'
import { mapState } from 'vuex'

const wordOfMouth = [
  'Chilliwack Starbucks Billboard',
  'Clubhouse',
  'Facebook',
  'Friend',
  'Google',
  'Instagram',
  'Jeff Booth/Price of Tomorrow',
  'Linkedin',
  'Meetup',
  'News Article / Media',
  'Podcast',
  'Reddit',
  'Spice Radio',
  'Tiktok',
  'Twitter',
  'Webinar / Virtual Meet Up',
  'YouTube',
  'Other'
]
export default {
  mixins: [formMixins, setupProfile],
  components: {
    ProfileCommon,
    BottomButtons,
  },
  data() {
    return {
      wordOfMouth,
      heardAboutUs: undefined,
      heardAboutUsOther: undefined,
      nextIsLoading: false,
    }
  },
  computed: {
    ...mapState('mixpanel', ['mixpanel'])
  },
  methods: {
    goNext() {
      this.nextIsLoading = true
      this.$refs.step5Form.validate().then((success) => {
        if (!success) {
          this.nextIsLoading = false
          return
        }
        const email = JSON.parse(localStorage.getItem('investor')).email
        updateInvestorProfile({
          email,
          heard_about_us: this.heardAboutUs,
          heard_about_us_other: this.heardAboutUs === 'Other' ? this.heardAboutUsOther : null
        }).then((data) => {
          let url = '/signup/address/1'
          const {
            unitNumber,
            streetNumber,
            locality,
            postalCode,
            country,
            administrativeAreaLevel1,
            mailingUnitNumber,
            mailingStreetNumber,
            mailingLocality,
            mailingPostalCode,
            mailingCountry,
            mailingAdministrativeAreaLevel1
          } = data
          if (
            unitNumber === mailingUnitNumber &&
            streetNumber === mailingStreetNumber &&
            locality === mailingLocality &&
            postalCode === mailingPostalCode &&
            country === mailingCountry &&
            administrativeAreaLevel1 === mailingAdministrativeAreaLevel1
          ) {
            url = '/signup/address/1?mailingIsSame=true'
          }
          this.nextIsLoading = false
          this.mixpanel.track('yq2dm9ag')
          this.$router.push(url)
        })
      })
    },
    goBack() {
      this.$emit('updateProgressRing', 'back')
      this.$router.replace('/signup/profile/4?noRedirect=true')
    },
  },
}
</script>
<style lang="scss" scoped>
.box {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 26px;
  padding-bottom: 26px;
  box-shadow: 0px 4px 50px rgba(215, 145, 51, 0.15);
  .step5 {
    &.no-other {
      padding-bottom: 130px;
    }
    &.is-other {
      padding-bottom: 80px;
    }
    .step-title {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 12px;
      padding-left: 10px;
    }
    .heard-about-us {
      margin-bottom: 8px;
    }
  }
}
</style>
