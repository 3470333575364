var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"step4Form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('div',{staticClass:"box plain-shadow-on-desktop"},[_c('ProfileCommon'),_c('div',{staticClass:"form-box"},[_c('div',{staticClass:"step4"},[_c('div',{staticClass:"step-title"},[_vm._v("Date of birth")]),_c('div',{staticClass:"step-hint"},[_vm._v("To invest, you must be "),_c('span',{attrs:{"data-testid":"age"}},[_vm._v(_vm._s(_vm.age))]),_vm._v(" years or older.")]),_c('div',{staticClass:"is-flex is-justify-content-space-between birthday"},[_c('ValidationProvider',{attrs:{"name":"year","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var passed = ref.passed;
var failed = ref.failed;
return [_c('b-field',{attrs:{"type":_vm.classForField({ passed: passed, failed: failed }),"message":errors[0]}},[_c('b-select',{staticClass:"is-medium-height is-blue year",attrs:{"name":"year","placeholder":"Year"},on:{"input":_vm.getDays},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}},_vm._l((_vm.years),function(option){return _c('option',{key:option,domProps:{"value":option}},[_vm._v(_vm._s(option))])}),0)],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"month","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var passed = ref.passed;
var failed = ref.failed;
return [_c('b-field',{attrs:{"type":_vm.classForField({ passed: passed, failed: failed }),"message":errors[0]}},[_c('b-select',{staticClass:"is-medium-height is-blue month",attrs:{"name":"month","placeholder":"Month","disabled":!_vm.year},on:{"input":_vm.getDays},model:{value:(_vm.month),callback:function ($$v) {_vm.month=$$v},expression:"month"}},_vm._l((_vm.months),function(option){return _c('option',{key:option.code,domProps:{"value":option.code}},[_vm._v(_vm._s(option.name))])}),0)],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"day","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var passed = ref.passed;
var failed = ref.failed;
return [_c('b-field',{attrs:{"type":_vm.classForField({ passed: passed, failed: failed }),"message":errors[0]}},[_c('b-select',{staticClass:"is-medium-height is-blue day",attrs:{"name":"day","placeholder":"Day","disabled":!_vm.year || !_vm.month},model:{value:(_vm.day),callback:function ($$v) {_vm.day=$$v},expression:"day"}},_vm._l((_vm.days),function(option){return _c('option',{key:option,domProps:{"value":option}},[_vm._v(_vm._s(option))])}),0)],1)]}}],null,true)})],1)])])],1),_c('BottomButtons',{attrs:{"nextDisabled":!valid,"loading":_vm.nextIsLoading},on:{"goNext":_vm.goNext,"goBack":_vm.goBack}}),_c('ConfirmModal',{attrs:{"title":"Oops!","text":("To invest, you must be " + _vm.age + " or older."),"showModal":_vm.showModal},on:{"closeModal":function($event){_vm.showModal = false}}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }