<template>
  <div class="profile-wrap is-relative">
    <div class="progress-ring-wrap has-text-centered">
      <div class="rotate-wrap">
        <vue-circle
          ref="progressRing"
          class="progress-ring"
          :progress="progress"
          :size="27"
          :reverse="false"
          line-cap="round"
          :fill="{color: '#4A26AA'}"
          empty-fill="#F0EEF8"
          :animation-start-value="0.0"
          :start-angle="0"
          insert-mode="append"
          :thickness="4"
          :show-percent="false">
        </vue-circle>
      </div>
    </div>
    <Step1 ref="step1" v-show="activeStep === '1'" @updateProgressRing="updateProgressRing"></Step1>
    <Step2 ref="step2" v-show="activeStep === '2'" @updateProgressRing="updateProgressRing"></Step2>
    <Step3 ref="step3" v-show="activeStep === '3'" @updateProgressRing="updateProgressRing"></Step3>
    <Step4 ref="step4" v-show="activeStep === '4'" @updateProgressRing="updateProgressRing"></Step4>
    <Step5 ref="step5" v-show="activeStep === '5'" @updateProgressRing="updateProgressRing"></Step5>
  </div>
</template>
<script>
import VueCircle from 'vue2-circle-progress/src/index.vue'
import Step1 from './ProfileStep1'
import Step2 from './ProfileStep2'
import Step3 from './ProfileStep3'
import Step4 from './ProfileStep4'
import Step5 from './ProfileStep5'
import { getInvestorData } from '@api/signup'

const mapStepToProgress = {
  '1': 25,
  '2': 38,
  '3': 50,
  '4': 75,
  '5': 100,
}

export default {
  components: {
    VueCircle,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
  },
  data() {
    return {
      investorInfo: {},
    }
  },
  computed: {
    progress() {
      return mapStepToProgress[this.activeStep]
    },
    activeStep() {
      return this.$route.params.step
    },
  },
  created() {
    this.getInvestorInfo()
  },
  methods: {
    getInvestorInfo() {
      getInvestorData().then((data) => {
        this.investorInfo = data
        localStorage.setItem('investor', JSON.stringify(data))
        this.autoPopulateAndRedirect()
      })
    },
    autoPopulateAndRedirect() {
      const {
        firstName,
        lastName,
        addyHandle,
        hashid,
        mobileNumber,
        birthdate,
        heardAboutUs,
        heardAboutUsOther,
        streetNumber,
        mailingStreetNumber,
        country,
      } = this.investorInfo

      const doRedirect = !this.$route.query.noRedirect

      if (!country) {
        this.$router.replace('/signup/selectProvince')
      } else if (doRedirect && mailingStreetNumber) { // goes to wallet for Canadian or questionnaire page for American
        const route = country === 'Canada' ? '/signup/wallet' : '/signup/questionnaire'
        this.$router.replace(route)
      } else if (doRedirect && streetNumber) { // goes to mailing address
        this.$router.replace('/signup/address/2')
      } else if (doRedirect && heardAboutUs) { // goes to residential address
        this.$router.replace('/signup/address/1')
      } else { // goes to profile setup
        this.$refs.step1.firstName = firstName
        this.$refs.step1.lastName = lastName
        if (addyHandle && addyHandle.substring(1) !== hashid) this.$refs.step2.addyHandle = addyHandle.substring(1)
        this.$refs.step3.mobileNumber = mobileNumber
        if (birthdate) {
          const arr = birthdate.split('-')
          this.$refs.step4.year = Number(arr[0])
          this.$refs.step4.month = Number(arr[1])
          this.$refs.step4.day = Number(arr[2])
          this.$refs.step4.getDays()
        }
        this.$refs.step5.heardAboutUs = heardAboutUs
        this.$refs.step5.heardAboutUsOther = heardAboutUsOther
        doRedirect && this.jumpToStep()
      }
    },
    jumpToStep() {
      const { lastName, addyHandle, hashid, mobileNumber, birthdate } = this.investorInfo
      let step = 1
      if (birthdate) {
        step = 5
      } else if (mobileNumber) {
        step = 4
      } else if (addyHandle && addyHandle.substring(1) !== hashid) {
        step = 3
      } else if (lastName) {
        step = 2
      } else {
        return
      }
      this.$router.replace(`/signup/profile/${step}`)
    },
    updateProgressRing(flag) {
      const nextStep = flag === 'next' ? String(Number(this.activeStep) + 1) : String(this.activeStep - 1)
      this.$refs.progressRing.updateProgress(mapStepToProgress[nextStep])
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === 'signupAddress') {
        vm.$refs.step1.$refs.step1Form.validate()
        vm.$refs.step2.$refs.step2Form.validate()
        vm.$refs.step2.addyHandleVerified = true
        vm.$refs.step2.addyHandleIsAvailable = true
        vm.$refs.step3.$refs.step3Form.validate()
        vm.$refs.step4.$refs.step4Form.validate()
        vm.$refs.step5.$refs.step5Form.validate()
      }
    })
  },
}
</script>
<style lang="scss" scoped>
.profile-wrap {
  padding: 0 25px 140px;
  .progress-ring-wrap {
    position: absolute;
    left: 0;
    top: 132px;
    width: 100%;
    .rotate-wrap {
      transform: rotate(-90deg);
    }
  }
}
</style>
