var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"step5Form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('div',{staticClass:"box plain-shadow-on-desktop"},[_c('ProfileCommon'),_c('div',{staticClass:"form-box"},[_c('div',{class:("step5 " + (_vm.heardAboutUs === 'Other' ? 'is-other' : 'no-other'))},[_c('div',{staticClass:"step-title"},[_vm._v("How did you hear about us?")]),_c('ValidationProvider',{attrs:{"name":"heardAboutUs","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var passed = ref.passed;
var failed = ref.failed;
return [_c('b-field',{attrs:{"type":_vm.classForField({ passed: passed, failed: failed }),"message":errors[0]}},[_c('b-select',{staticClass:"is-medium-height is-blue heard-about-us",attrs:{"name":"heardAboutUs","placeholder":"Select...","expanded":""},model:{value:(_vm.heardAboutUs),callback:function ($$v) {_vm.heardAboutUs=$$v},expression:"heardAboutUs"}},_vm._l((_vm.wordOfMouth),function(option){return _c('option',{key:option,domProps:{"value":option}},[_vm._v(_vm._s(option))])}),0)],1)]}}],null,true)}),(_vm.heardAboutUs === 'Other')?_c('ValidationProvider',{attrs:{"name":"heardAboutUsOther","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var passed = ref.passed;
var failed = ref.failed;
return [_c('div',{staticClass:"field heard-about-us-other-field"},[_c('div',{staticClass:"control has-icons-right"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.heardAboutUsOther),expression:"heardAboutUsOther",modifiers:{"trim":true}}],class:("input is-medium-height " + (_vm.classForField({ passed: passed, failed: failed }))),attrs:{"placeholder":"How did you hear about us?"},domProps:{"value":(_vm.heardAboutUsOther)},on:{"input":function($event){if($event.target.composing){ return; }_vm.heardAboutUsOther=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"icon is-small is-right"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"icon-exclamation",attrs:{"src":require("../../assets/icons/exclamation.svg"),"alt":"is-danger"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"icon-check",attrs:{"src":require("../../assets/icons/check.svg"),"alt":"is-success"}})])]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}):_vm._e()],1)])],1),_c('BottomButtons',{attrs:{"nextDisabled":!valid,"loading":_vm.nextIsLoading},on:{"goNext":_vm.goNext,"goBack":_vm.goBack}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }