<template>
  <div>
    <div class="page-title has-text-centered">Build Your Profile</div>
    <div class="page-subtitle has-text-centered">
      <div>To build your profile, we need to</div>
      <div>gather a few things about you first!</div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    activeStep() {
      return this.$route.params.step
    },
  }
}
</script>
<style lang="scss" scoped>
.page-title {
  font-size: 32px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 24px;
  letter-spacing: 0.03em;
}
.page-subtitle {
  font-size: 16px;
  line-height: 19px;
  padding-bottom: 102px;
}
</style>
