const Months = [
  { code: 1, name: 'Jan' },
  { code: 2, name: 'Feb' },
  { code: 3, name: 'Mar' },
  { code: 4, name: 'Apr' },
  { code: 5, name: 'May' },
  { code: 6, name: 'Jun' },
  { code: 7, name: 'Jul' },
  { code: 8, name: 'Aug' },
  { code: 9, name: 'Sept' },
  { code: 10, name: 'Oct' },
  { code: 11, name: 'Nov' },
  { code: 12, name: 'Dec' }
]

export default Months
