<template>
  <ValidationObserver ref="step3Form" v-slot="{ valid }">
    <div class="box plain-shadow-on-desktop">
      <ProfileCommon></ProfileCommon>
      <div class="form-box">
        <div class="step3">
          <div class="step-title">Phone number</div>
          <ValidationProvider name="mobileNumber" rules="required|phone-number" v-slot="{ errors, passed, failed }">
            <div class="field mobile-number-field">
              <div class="control has-icons-right">
                <input :class="`input is-medium-height ${classForField({ passed, failed })}`" placeholder="555-666-8888" v-model.trim="mobileNumber">
                <span class="icon is-small is-right">
                  <img
                    v-show="failed"
                    class="icon-exclamation"
                    src="../../assets/icons/exclamation.svg"
                    alt="is-danger">
                  <img
                    v-show="passed"
                    class="icon-check"
                    src="../../assets/icons/check.svg"
                    alt="is-success">
                </span>
              </div>
              <p class="help is-danger" v-show="failed">{{errors[0]}}</p>
            </div>
          </ValidationProvider>
        </div>
      </div>
    </div>
    <BottomButtons :nextDisabled="!valid" :loading="nextIsLoading" @goNext="goNext" @goBack="goBack"></BottomButtons>
  </ValidationObserver>
</template>
<script>
import formMixins from '@utils/mixins/form-mixins.js'
import setupProfile from '@utils/mixins/setup-profile.js'
import ProfileCommon from './ProfileCommon.vue'
import BottomButtons from '@components/button/bottom-buttons.vue'
import { updateInvestorProfile } from '@api/signup'
import { mapState } from 'vuex'

export default {
  mixins: [formMixins, setupProfile],
  components: {
    ProfileCommon,
    BottomButtons,
  },
  data() {
    return {
      mobileNumber: '',
      nextIsLoading: false,
    }
  },
  computed: {
    ...mapState('mixpanel', ['mixpanel']),
  },
  methods: {
    goNext() {
      this.nextIsLoading = true
      this.$refs.step3Form.validate().then((success) => {
        if (!success) {
          this.nextIsLoading = false
          return
        }
        const email = JSON.parse(localStorage.getItem('investor')).email
        updateInvestorProfile({
          email,
          mobile_number: this.mobileNumber,
        }).then(() => {
          this.nextIsLoading = false
          this.$emit('updateProgressRing', 'next')
          this.mixpanel.track('ym3ciwfn')
          this.$router.push(`/signup/profile/${Number(this.activeStep) + 1}`)
        })
      })
    },
    goBack() {
      this.$emit('updateProgressRing', 'back')
      this.$router.replace('/signup/profile/2?noRedirect=true')
    },
  },
}
</script>
<style lang="scss" scoped>
.box {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 26px;
  padding-bottom: 26px;
  box-shadow: 0px 4px 50px rgba(215, 145, 51, 0.15);
  .step3 {
    .step-title {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 12px;
      padding-left: 10px;
    }
    .mobile-number-field {
      padding-bottom: 138px;
    }
  }
}
</style>
